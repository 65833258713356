import classes from './Navbar.module.scss'

import asterisk_white from '../../assets/icons/asterisk_white.svg'
import menu_white from '../../assets/icons/menu_white.svg'


const Navbar = () => {
    return(
        <div className={classes.container}>
            <a href={process.env.REACT_APP_PUBLIC_URL} className={classes.navbar_main}>
                
                <div className={classes.navbar_main_left}>
                    <img src={asterisk_white} alt='Prompt'/>
                    <div>Prompt <small>20.0</small></div>
                </div>
                <div className={classes.navbar_main_right}>
                    <img src={menu_white} alt='menu'/>
                </div>
            </a>
        </div>
    )
}

export default Navbar;