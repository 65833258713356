import classes from './Chat.module.scss'
import natasha from '../../assets/imags/natasha.png'

import {TimeOutHandler, decryptData} from '../../utils/helper_functions'

import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom'

import { ImpulseSpinner } from "react-spinners-kit";



const Chat = () => {
    const params = useParams()
    const ref = useRef(null)
    const API_URL = process.env.REACT_APP_API_URL
    const aes_salt_key = process.env.REACT_APP_AES_SALT
    const agent_name = params.agent_name

    const prompt_uuid = decryptData(localStorage.getItem('prompt_uuid', null), aes_salt_key)
    const prompt_access_token = decryptData(localStorage.getItem('prompt_access_token', null), aes_salt_key)
    const prompt_chatroom_key = localStorage.getItem('prompt_chat_room', '')

    const [chats, setChats] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [reloadAppText, setReloadAppText] = useState(false)
    const [msg, setMsg] = useState('')
    const [newMsg, setNewMsg] = useState('')
    
    const [msgSendInProgress, setMsgSendInProgress] = useState(false)
    const [lastDate, setLastDate] = useState('')

    const [isChatDisconnected, setIsChatDisconnected] = useState(false)
    const [isMsgEmpty, setIsMsgEmpty] = useState(true)
    const [msgSentError, setMsgSentError] = useState(false)


    // websocket events and handler functions, useEffect
    // const onSignatureErr = async (value) => {
    //     console.log("SOCKET ERROR :", value)
    //     if(msgSendInProgress){
    //         setInsertChats(false) //dont insert chats anymore in chats array
    //         console.log("token expired when sending")
    //         await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
    //         setAccessTokenChanged(true)
    //         // handleMsgSend()
    //         setMsgSendInProgress(false)
    //     }else{
    //         console.log("token expired before socket connected :")
    //         await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
    //         setAccessTokenChanged(true)
    //         // socket.emit('reconnect')
    //     }
    // }
    // const onError = async (value) => {
    //     console.log("ERROR - maybe access token, chat room token or uuid deleted :", value)
    //     await get_new_access_token(prompt_uuid, prompt_access_token, prompt_chatroom_key, API_URL, aes_salt_key)
    //     setAccessTokenChanged(true)
    // }

    // ################################# functions #################################
    // function onInitialMessageReceived(value){
    //     const all_chats = JSON.parse(value)
    //     setChats(all_chats)
    //     setIsLoading(false)

    //     if(all_chats[all_chats.length - 1]['agent_status']=== 'INACTIVE'){
    //         console.log("WS Disconnected. so disabling input")
    //         setIsChatDisconnected(true)
    //     }
    // }
    // function onInitialMessageReceivedClosed(){
    //     console.log("Initialization closed")
    // }


    // function onMessageReceived(value){
    //     if(!msgSentError){
    //         setIsLoading(false)
    //         const sent_msg = JSON.parse(value)
    //         setChats([...chats, sent_msg])

    //         if(sent_msg['agent_status'] === 'INACTIVE'){
    //             console.log("WS Disconnected. so disabling input")
    //             setIsChatDisconnected(true)
    //         }
    //     }
    // }
    // function onMessageReceivedClosed(){
    //     console.log("Connection closed")
    // }

    // useEffect(()=>{
    //     // console.log("SOCKET IN INITIALIZATION IS :", socket)
    //     socket.on('initialization', onInitialMessageReceived);
    //     return () => {
    //         socket.off('initialization', onInitialMessageReceivedClosed);
    //     }
    // }, [socket])

    // useEffect(() => {
    //     // console.log("Now chats are :", chats)
    //     socket.on('msg_from_server', onMessageReceived);
    //     return () => {
    //         socket.off('msg_from_server', onMessageReceivedClosed);
    //     }
    // }, [socket, chats])



    const get_initial_msg = async() => {
        console.log("getting initial msg v20 :", API_URL, prompt_uuid, prompt_chatroom_key)

        const res = await fetch(`${API_URL}/api/v1/ws`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${prompt_access_token}`,
                'uuid':prompt_uuid,
                'chattoken':prompt_chatroom_key
            }
        })
        const res_status = res.status
        const data = await res.json()
        console.log('initial chats v20 :', data)
        if(res_status === 200) {
            const data_chats = data['chats']
            setChats(data_chats)
            setIsLoading(false)

            if(data_chats.length>0){
                if(data_chats[data_chats.length-1]['agent_status']==='INACTIVE'){
                    setIsChatDisconnected(true)
                }
            }
        }else{
            setIsLoading(false)
            setReloadAppText(true)
            console.log("failed to get initial chats v20 :", res_status, data)
        }
    }

    useEffect(()=>{
        get_initial_msg()
    }, [])



    // scroll to bottom functionality
    const scrollToBottom = () => {
        const lastChildElement = ref.current?.lastElementChild;
        lastChildElement?.scrollIntoView({ behavior: 'smooth' });      
    }
    useEffect(()=>{
        scrollToBottom()
    }, [chats])
   
    
    // type and send msg functionality
    const handleMsgSend = async () => {
        setMsgSentError(false)
        if(!isMsgEmpty && msg!=='' && !isLoading){
            var when;
            if(msgSendInProgress){
                when = lastDate
            }else{
                const date = new Date()
                when = date.getHours()+":"+date.getMinutes()
                setLastDate(when)
            }
            console.log("msg send again :", when)

            var msg_to_be_sent = {
                from: prompt_chatroom_key,
                msg: msg,
                when: when,
                status: 'delivered'
            }
            
            setChats([...chats, msg_to_be_sent])
            setMsg('')
            setIsLoading(true)

            const res = await fetch(`${API_URL}/api/v1/ws`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${prompt_access_token}`,
                },
                body: JSON.stringify({'msg_to_be_sent':msg_to_be_sent})
            })

            const res_status = res.status
            const data = await res.json()
            console.log('returned res chats :', data['chat'])
            if(res_status === 200) {
                setNewMsg(data['chat'])
                setIsLoading(false)

                if(data['close_connection']===true){
                    TimeOutHandler(()=>{
                        setIsChatDisconnected(true)
                    }, 3000)
                }
            }else{
                setIsLoading(false)
                console.log("failed to send subsequent chats", res_status, data)
            }
        }
    }

    useEffect(()=>{
        if(typeof newMsg === 'object'){
            setChats([...chats, newMsg])
        }
    }, [newMsg])




    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleMsgSend()
        }
    };   
    const handleMsgInput = (e) => {
        const input_msg = e.target.value
        setMsg(input_msg)
        if(input_msg===''){
            setIsMsgEmpty(true)
        }else{
            setIsMsgEmpty(false)
        }
    }

    const process_msg = (msg) => {
        if(msg.includes('https://jojo.co.il')){
            console.log("MSG includes url")
            const msg_list = msg.split('https://jojo.co.il')
            return <>
                {msg_list[0]}
                <a className={classes.anchor_tag} href="https://jojo.co.il" rel="noreferrer" target="_blank">https://jojo.co.il</a>
                {msg_list[msg_list.length-1]}
            </>        
        }else{
            return msg
        }
    }

    

    const get_offline_placeholder = () => {
        return <>{`${agent_name.charAt(0).toUpperCase() + agent_name.slice(1)} is offline now. Reach her at:`}<a className={classes.anchor_tag_secondary} href="https://jojo.co.il" rel="noreferrer" target="_blank">https://jojo.co.il</a></> 
    }



    return(
        <div className={classes.chat_div}>
            <div className={classes.chat_div_main}>
                <div className={classes.chat_div_main_left}>
                    <img src={natasha} alt=''/>
                </div>

                {/* chat section */}
                <div className={classes.chat_div_main_right}>
                    <div className={classes.chat_div_chat}>
                        <div className={classes.chat_div_chat_scroll}>
                            <div ref={ref} className={classes.chat_div_chat_scroll_100}>
                                {/* map all chats to div */}
                                {chats.map((item, index) => 
                                <div key={index}>
                                    {item['from'] === 'agent'?
                                        (
                                            <div className={classes.each_chat_div_parent_left}>
                                                <div className={classes.each_chat_div_left}>
                                                    <div className={classes.each_chat_div_left_profile}>
                                                        <div className={classes.chat_div_chat_icon}>
                                                            <img src={natasha} alt='natasha'/>
                                                        </div>
                                                        <div className={(item['agent_status']==='INACTIVE' && isChatDisconnected)?classes.inactive_status: classes.active_status}></div>
                                                    </div>
                                                    <div className={classes.chat_div_chat_msg}>
                                                        {process_msg(item['msg'])}
                                                    </div>
                                                    <div className={classes.chat_div_chat_msg_time_status_div}>
                                                        <div className={classes.chat_div_chat_msg_time}>
                                                            {item['when']}
                                                        </div>
                                                        <div className={item['status']==='sent'?classes.chat_div_chat_msg_status_gray:classes.chat_div_chat_msg_status}>
                                                            ✓✓
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ):(
                                            <div className={classes.each_chat_div_parent_right}>
                                                <div className={`${classes.each_chat_div_right}`}>
                                                    <div className={classes.chat_div_chat_msg}>
                                                        {item['msg']}
                                                    </div>
                                                    <div className={classes.chat_div_chat_msg_time_status_div}>
                                                        <div className={classes.chat_div_chat_msg_time}>
                                                            {item['when']}
                                                        </div>
                                                        <div className={item['status']==='sent'?classes.chat_div_chat_msg_status_gray:classes.chat_div_chat_msg_status}>
                                                            ✓✓
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )} 
                                </div>)}


                                {/* typing animation when agent is producing result */}
                                {(isLoading && chats.length === 0) ? (
                                    <div className={classes.each_chat_div_parent_center}>
                                        {/* <div className={classes.each_chat_div_left}> */}
                                            <div className={classes.text_spinner}>
                                                <ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>
                                            </div>
                                        {/* </div> */}
                                    </div>
                                ):(
                                    <>
                                        {isLoading && 
                                        <div className={classes.each_chat_div_parent_left}>
                                            <div className={classes.each_chat_div_left}>
                                                <div className={classes.each_chat_div_left_profile}>
                                                    <div className={classes.chat_div_chat_icon}>
                                                        <img src={natasha} alt='natasha'/>
                                                    </div>
                                                    <div className={classes.active_status}></div>
                                                </div>
                                                <div className={classes.text_spinner}>
                                                    <ImpulseSpinner size={30} frontColor="#ffffff80"/>
                                                </div>
                                            </div>
                                        </div>}
                                    </>
                                )}

                                {reloadAppText && 
                                    <div className={classes.each_chat_div_parent_left}>
                                        <div className={classes.each_chat_div_left}>
                                            <div className={classes.each_chat_div_left_profile}>
                                                <div className={classes.chat_div_chat_icon}>
                                                    <img src={natasha} alt='natasha'/>
                                                </div>
                                                <div className={classes.active_status}></div>
                                            </div>
                                            <div className={classes.chat_div_chat_msg}>
                                                Something went wrong. Please reload the app.
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                            {isChatDisconnected?(
                                <div className={classes.input_div_inactive}>
                                    {get_offline_placeholder()}
                                </div>
                            ):(
                                <div className={classes.input_div}>
                                    {chats.length === 0 ? (<input type='text' placeholder={`Say Hello to ${agent_name}!`} value={msg} onKeyDown={handleKeyDown} onChange={handleMsgInput}/>):(<input type='text' placeholder={`Send message to ${agent_name}!`} value={msg} onKeyDown={handleKeyDown} onChange={handleMsgInput}/>)}
                                    <button onClick={handleMsgSend}>{isLoading?<ImpulseSpinner size={30} frontColor="#fff" backColor="#ffffff60"/>:"SEND"}</button>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat;